import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { LoadingDialogComponent } from '../modals/loading-dialog/loading-dialog.component';
import { FormioRendererI18n } from '../components/data-interaction/formio-renderer/formio-renderer.component';
import { VcHistoryElement } from '../models/view-content.models/view-content.model';
import { CP2_User } from '../models/view-content.models/view-content-personal-domain.model';
import dayjs from 'dayjs';

@Injectable({
    providedIn: 'root',
})
export class ToolboxService {
    private static readonly TAG = 'ToolboxService';

    public constructor(private dialog: MatDialog) {}

    public async executeInLoading(f: () => Promise<any>, message?: string, minTime: number = 0): Promise<any> {
        const dialogRef = this.dialog.open(LoadingDialogComponent, {
            disableClose: true,
            data: { message },
        });

        const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

        const resultPromise = f();
        const timerPromise = delay(minTime);

        try {
            const result = await Promise.all([resultPromise, timerPromise]);
            return result[0];
        } finally {
            dialogRef.close();
        }
    }

    public downloadTextFile(content: string, fileName: string): void {
        const blob = new Blob([content], { type: 'text/plain' });

        const link = document.createElement('a');

        // Step 3: Create a URL for the Blob and set it as the href attribute
        const url = window.URL.createObjectURL(blob);
        link.href = url;

        // Step 4: Set the download attribute with the desired file name
        link.download = fileName;

        // Step 5: Append the link to the document body (required for Firefox)
        document.body.appendChild(link);

        // Step 6: Programmatically click the link to trigger the download
        link.click();

        // Step 7: Clean up by removing the link and revoking the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    /**
     * Builds a new object containing a merge of the two parameter objects. In case of conflict will choose the value of the first parameter
     */
    public mergeI18n(p1: FormioRendererI18n, p2: FormioRendererI18n): FormioRendererI18n {
        const res: FormioRendererI18n = {};
        const langs = ['en', 'de'];

        for (const l of langs) {
            res[l] = {};
            const allKeys = [...Object.keys(p2), ...Object.keys(p1)];
            for (const k of allKeys) {
                if (Object.hasOwn(p1, k)) {
                    res[k] = p1[k];
                } else {
                    res[k] = p2[k];
                }
            }
        }

        return res;
    }

    public areCP2UsersEqual(u1: CP2_User, u2: CP2_User): boolean {
        return u1.uuid === u2.uuid;
    }

    public deepEqual<T>(a: any, b: any): boolean {
        if (a === b) return true;

        if (typeof a !== typeof b || a === null || b === null) return false;

        if (typeof a === 'object') {
            const keysA = Object.keys(a);
            const keysB = Object.keys(b);

            if (keysA.length !== keysB.length) return false;

            for (const key of keysA) {
                if (!this.deepEqual(a[key], b[key])) return false;
            }

            return true;
        }

        return false;
    }

    // Function to compare two VcHistoryElement<T> objects
    public areVcHistoryElementsEqual<T>(e1: VcHistoryElement<T>, e2: VcHistoryElement<T>): boolean {
        if (!dayjs(e1.modifiedAt).isSame(e2.modifiedAt)) return false;

        if (!this.areCP2UsersEqual(e1.modifiedBy, e2.modifiedBy)) return false;

        return true;
    }

    public sortVcHistory<T>(array: VcHistoryElement<T>[]): VcHistoryElement<T>[] {
        return array.sort((a, b) => {
            const dateA = dayjs(a.modifiedAt);
            const dateB = dayjs(b.modifiedAt);

            const isValidA = dateA.isValid();
            const isValidB = dateB.isValid();

            if (!isValidA && !isValidB) {
                return 0; // Both are invalid, maintain original order
            }

            if (!isValidA) {
                return 1; // A is invalid, move it to the end
            }

            if (!isValidB) {
                return -1; // B is invalid, move it to the end
            }

            // Compare valid dates: most recent first
            return dateB.diff(dateA);
        });
    }
}
